<template>
	<BaseLayout>
		<Toolbar @clickLeft="isLoading ? null : $router.push('/home')">
			<template v-slot:start>
				<Icon class="h-5 w-5" icon="angleLeft" />
			</template>
			<p>{{ $t('shipping.reserve') }}</p>
		</Toolbar>
		<div class="flex flex-col space-y-3 px-5 text-lg">
			<List :title="$t('shipping.orderType')">
				<div class="my-4 flex w-full justify-around text-primary">
					<template v-for="(method, index) in currentShopSaleMethods" :key="index">
						<button v-if="verifySaleMethod(appType, method.type)" class="inline-flex w-40 justify-center rounded-3xl border-2 border-primary py-3" @click="changeSaleMethod(method)" :disabled="isLoading" :class="method.type == saleMethod ? 'bg-primary text-white' : ' '">
							<p class="px-2 text-sm">
								{{ method.name[currentLang] }}
								<!-- {{ $t('shipping.onSite') }} -->
							</p>
						</button>
					</template>

					<!-- <button class="inline-flex w-40 justify-center rounded-3xl border-2 border-primary py-3" @click="shipment.type = 'DELIVERY'" :disabled="isLoading" :class="shipment.type === 'DELIVERY' ? 'bg-primary text-white' : ''">
						<p class="px-2 text-sm">{{ $t('shipping.Delivery') }}</p>
					</button> -->
				</div>
			</List>

			<List v-if="saleMethod === 'DELIVERY'" :title="$t('shipping.orderDetails')">
				<list-item :title="$t('shipping.deliveredBy')">
					<select name="" id="" v-model="shipment.method" class="w-full appearance-none bg-white text-right" :class="typeof shipment.method === 'undefined' ? 'text-muted' : 'text-black'" :disabled="isLoading" dir="rtl">
						<option value="undefined" disabled selected>{{ $t('shipping.selectDeliveredBy') }}</option>
						<option v-for="(option, index) in deliveryTypeOptions" :key="index" :value="option">{{ option.name[currentLang] }}</option>
					</select>
				</list-item>

				<list-item :title="$t('shipping.shippingDate')">
					<button v-if="isNative" :class="dateTime.date ? 'text-black' : 'text-muted'" @click="onDatePicker('date')">{{ dateTime.date ? dateTime.date : $t('shipping.shippingEditDate') }}</button>
					<input v-else v-model="dateTime.date" class="w-full bg-transparent text-right" type="date" :placeholder="$t('shipping.shippingEditDate')" :disabled="isLoading" dir="rtl" />
				</list-item>

				<list-item :title="$t('shipping.shippingTime')">
					<button v-if="isNative" :class="dateTime.time ? 'text-black' : 'text-muted'" @click="onDatePicker('time')">{{ dateTime.time ? dateTime.time : $t('shipping.shippingEditTime') }}</button>
					<input v-else v-model="dateTime.time" class="w-full bg-transparent text-right" type="time" :placeholder="$t('shipping.shippingEditTime')" :disabled="isLoading" dir="rtl" />
				</list-item>

				<list-item v-if="shipment.method" :title="$t('shipping.shippingAddress')">
					<select v-model="shipment.address" class="w-full appearance-none whitespace-pre-line bg-white text-right" :class="typeof shipment.address === 'undefined' ? 'text-muted' : 'text-black'" :disabled="isLoading" dir="rtl">
						<option value="undefined" disabled selected>{{ $t('shipping.shippingSelectAddress') }}</option>
						<option v-for="(option, index) in addressOptions" :key="index" :value="option" class="text-black">{{ option.addressLine1 }}</option>
					</select>
				</list-item>
			</List>
		</div>
		<Footer>
			<Button :disabled="isLoading" @click="submit">{{ $t('shipping.Submit') }}</Button>
		</Footer>
	</BaseLayout>

	<Toast v-if="error" @close="error = null">{{ error.message }}</Toast>
</template>

<script>
import { appType } from '../../package.json'
import { storeToRefs } from 'pinia'
import { ref, computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useSharedStore } from '@/store/shared'
import { useShopStore } from '@/store/shop'
import { useCartStore } from '@/store/cart'
import { useCustomerStore } from '@/store/customer'
import { Capacitor } from '@capacitor/core'
import { DatePicker } from '@pantrist/capacitor-date-picker'
import moment from 'moment'
import { useI18n } from 'vue-i18n'
import { saleApi } from 'gox-sdk'

export default {
	setup() {
		const router = useRouter()
		const store = useStore()
		const { currentLang } = storeToRefs(useSharedStore())
		const { currentShop, currentShopSaleMethods } = storeToRefs(useShopStore())
		const { verifySaleMethod } = useShopStore()

		const { currentCart, saleMethod } = storeToRefs(useCartStore())
		const { currentCustomer } = storeToRefs(useCustomerStore())
		const { changeSaleMethod } = useCartStore()
		const dateTime = ref({})

		const shipment = ref({ ...currentCart.value.deliverySetting })
		const { t } = useI18n({ useScope: 'global' })
		const deliveryTypeOptions = currentShop.value.deliverySetting.methods
		const addressOptions = computed(() => {
			if (shipment.value.method?.type === 'MERCHANT')
				return [
					{ country: '', state: '', city: '', district: '', addressLine2: '', addressLine1: t('shipping.myjetmallDelivery1') },
					{ country: '', state: '', city: '', district: '', addressLine2: '', addressLine1: t('shipping.myjetmallDelivery2') },
					{ country: '', state: '', city: '', district: '', addressLine2: '', addressLine1: t('shipping.myjetmallDelivery3') },
					{ country: '', state: '', city: '', district: '', addressLine2: '', addressLine1: t('shipping.myjetmallDelivery4') },
					{ country: '', state: '', city: '', district: '', addressLine2: '', addressLine1: t('shipping.myjetmallDelivery5') },
					{ country: '', state: '', city: '', district: '', addressLine2: '', addressLine1: t('shipping.myjetmallDelivery6') },
				]

			return currentCustomer.value.deliveryAddresses
		})

		const isNative = Capacitor.isNativePlatform()

		const error = ref(null)
		const isLoading = computed(() => store.state.loadingState)
		const selectedDelivery = ref()
		onMounted(() => {
			shipment.value.method = currentShop.value.deliverySetting.methods[0]
			if (shipment.value.time) {
				dateTime.value.date = moment(shipment.value.time).format('YYYY-MM-DD')
				dateTime.value.time = moment(shipment.value.time).format('HH:mm')
			}
		})
		function onDatePicker(mode) {
			const isToday = dateTime.value.date === moment().format('YYYY-MM-DD')
			DatePicker.present({
				mode: mode,
				date: dateTime.value[mode] ? dateTime.value[mode] : null,
				format: mode === 'date' ? 'YYYY-MM-dd' : 'HH:mm',
				min: mode === 'date' ? moment().format('YYYY-MM-DD') : isToday ? moment().format('HH:mm') : null,
			}).then(date => {
				dateTime.value[mode] = date.value
			})
		}

		async function submit() {
			try {
				store.commit('setLoadingState', { loadingState: true })
				error.value = null

				if (moment(dateTime.value.date, 'YYYY-MM-DD') > moment().add(1, 'months')) throw new Error(t(`cart.dateError`))

				const deliverySetting = {
					time: Number(moment(dateTime.value.date + ' ' + dateTime.value.time, 'YYYY-MM-DD HH:mm').format('x')),
					address: shipment.value.address,
					method: shipment.value.method,
				}
				const pickupSetting = {
					time: Number(moment(dateTime.value.date + ' ' + dateTime.value.time, 'YYYY-MM-DD HH:mm').format('x')),
				}
				if (saleMethod.value === 'ONSITE') return router.push('/cart')
				if (saleMethod.value === 'DELIVERY') {
					await saleApi.updateSaleDeliverySetting(currentCart.value.id, { deliverySetting: deliverySetting })
				} else {
					await saleApi.updateSalePickupSetting(currentCart.value.id, { pickupSetting: pickupSetting })
				}

				// await store.dispatch('setCartShipment', { shipment: shipment.value })
				router.push('/cart')
				store.commit('setLoadingState', { loadingState: false })
			} catch (e) {
				console.error(e)
				error.value = e
				store.commit('setLoadingState', { loadingState: false })
				setTimeout(() => {
					error.value = null
				}, 10000)
			}
		}

		return {
			currentLang,
			dateTime,
			// time,
			appType,
			currentShopSaleMethods,
			currentCart,
			verifySaleMethod,
			saleMethod,
			shipment,
			deliveryTypeOptions,
			addressOptions,
			isNative,
			error,
			isLoading,
			onDatePicker,
			submit,
			selectedDelivery,
			changeSaleMethod,
		}
	},
}
</script>
